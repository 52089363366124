import { useMemo, useState } from 'react';

import { Button, Form, Stack } from 'react-bootstrap';

import CommonDialog from '@components/Modals/CommonDialog';
import { SUPPORT_EMAIL } from '@constants/constants';
import { IExtendedClinician, OrganizationRole } from '@customTypes/clinician';
import { getClinicianName } from '@helpers/clinician';

interface IProps {
  onClose: () => void;
  onConfirm: (user: IExtendedClinician) => void;
  user: IExtendedClinician;
}

export default function EditUserDialog({ user, onClose, onConfirm }: IProps) {
  const [isAdmin, setIsAdmin] = useState(user.organizational_roles?.includes(OrganizationRole.ADMIN));
  const [isOrgDelegate, setIsOrgDelegate] = useState(user.organizational_roles?.includes(OrganizationRole.DELEGATE));
  const isClinician = useMemo(
    () => user.organizational_roles?.includes(OrganizationRole.CLINICIAN),
    [user.organizational_roles],
  );
  const clinicianName = useMemo(() => getClinicianName(user), [user]);
  const hasNpi = useMemo(() => !!user.npi && user.npi !== '000000000', [user.npi]);

  const isDirty = useMemo(() => {
    return (
      user.organizational_roles?.includes(OrganizationRole.ADMIN) !== isAdmin ||
      user.organizational_roles?.includes(OrganizationRole.DELEGATE) !== isOrgDelegate
    );
  }, [user.organizational_roles, isAdmin, isOrgDelegate]);

  const onSave = () => {
    const newRoles: OrganizationRole[] = [];

    if (isAdmin) {
      newRoles.push(OrganizationRole.ADMIN);
    }
    if (isOrgDelegate) {
      newRoles.push(OrganizationRole.DELEGATE);
    }
    // It can't be overwritten for now
    if (isClinician) {
      newRoles.push(OrganizationRole.CLINICIAN);
    }

    onConfirm({ ...user, organizational_roles: newRoles });
    onClose();
  };

  return (
    <CommonDialog
      title='Edit User'
      onClose={onClose}
      onConfirm={onSave}
      confirmBtnProps={{
        children: 'Save Changes',
        disabled: !isDirty,
      }}
      modalProps={{
        className: 'edit-user-dialog',
      }}
    >
      <Stack gap={4}>
        <div className='d-flex flex-column gap-1'>
          <p className='fw-medium fs-5 mb-0'>{clinicianName}</p>
          <p className='mb-0'>{user.email}</p>
          {hasNpi && isClinician && <p className='mb-0'>NPI: {user.npi}</p>}
        </div>

        <fieldset className='d-flex flex-column gap-6p'>
          <legend className='mb-0 fs-6'>Select user roles:</legend>
          <Form.Group className='ms-2'>
            <Form.Check
              type='checkbox'
              id={`${OrganizationRole.ADMIN}Check`}
              label='Admin'
              defaultChecked={isAdmin}
              onChange={() => setIsAdmin(oldValue => !oldValue)}
            />
          </Form.Group>
          <Form.Group className='ms-2'>
            <Form.Check id={`${OrganizationRole.CLINICIAN}Check`}>
              <Form.Check.Input type='checkbox' readOnly className='opacity-50' checked={isClinician} />
              <Form.Check.Label>
                Ordering Provider†
                <br />
                (cannot be changed)
              </Form.Check.Label>
            </Form.Check>
          </Form.Group>
          <Form.Group className='ms-2'>
            <Form.Check
              type='checkbox'
              id={`${OrganizationRole.DELEGATE}Check`}
              label='Organization Delegate'
              defaultChecked={isOrgDelegate}
              onChange={() => setIsOrgDelegate(oldValue => !oldValue)}
            />
          </Form.Group>
          <p className='mb-0 fs-body text-second'>†An ordering provider is a physician of record with a verified NPI</p>
        </fieldset>

        <p className='text-left fs-body mb-0'>
          <span className='align-middle'>Contact</span>
          <Button
            variant='link'
            className='p-0 border-0 mx-1 fs-body text-decoration-none fw-medium'
            href={`mailto:${SUPPORT_EMAIL}`}
            target='_blank'
          >
            {SUPPORT_EMAIL}
          </Button>
          <span className='align-middle'>to update profile information or to request account deactivation.</span>
        </p>
      </Stack>
    </CommonDialog>
  );
}

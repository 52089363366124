import { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { DatatableWrapper, TableBody, TableColumnType, TableHeader } from 'react-bs-datatable';
import { Button, Stack, Table } from 'react-bootstrap';

import InfoIconButton from '@components/Button/InfoIconButton';
import ExpandableSection from '@components/ExpandableSection/ExpandableSection';
import InfoDialog from '@components/Modals/InfoDialog';
import NoDraftsContent from '../NoDraftsContent/NoDraftsContent';

interface IDrafts {
  numOfDrafts: number;
}

export default function Drafts({ numOfDrafts }: IDrafts) {
  const [showInfoDialog, setShowInfoDialog] = useState<boolean>(false);

  const headers: TableColumnType<{
    name: string;
    orderingProvider: string;
    createdBy: string;
    lastSaved: string;
    delete: string;
  }>[] = [
    {
      title: 'Name',
      prop: 'name',
      isSortable: true,
      alignment: { horizontal: 'left' },
      cell: row => (
        <a href='/' className='text-decoration-none'>
          {row.name}
        </a>
      ),
    },
    {
      title: 'Ordering Provider',
      prop: 'orderingProvider',
      isSortable: true,
      alignment: { horizontal: 'left' },
    },
    {
      title: 'Created By',
      prop: 'createdBy',
      isSortable: true,
      alignment: { horizontal: 'left' },
    },
    {
      title: 'Last Saved',
      prop: 'lastSaved',
      isSortable: true,
      alignment: { horizontal: 'left' },
    },
    {
      title: 'Delete',
      prop: 'delete',
      // classes below are important! Makes delete button align nicely with text in other cols
      cellProps: { className: 'align-middle py-0' },
      cell: row => (
        <Button
          size='sm'
          variant='ghost'
          className='delete-btn'
          onClick={() => {
            alert('delete me');
          }}
          aria-label={`Delete ${row.name}`}
        >
          <FontAwesomeIcon icon={faTrashCan} className='text-danger' fontSize={16} />
        </Button>
      ),
    },
  ];

  const body = Array.from(new Array(25), () => {
    return {
      name: 'Name of draft',
      orderingProvider: 'Ramida Jiranorraphat, M.D.',
      createdBy: 'Claymore Alexander Keen, L.P.N.',
      lastSaved: '01/01/2025',
      delete: '',
    };
  });

  return (
    <>
      <ExpandableSection
        id='drafts'
        expandableContentId='draftsContent'
        headerTitle={
          <>
            <span>Drafts ({numOfDrafts})</span>
            <InfoIconButton onClick={() => setShowInfoDialog(true)} aria-label='Drafts Info Button' />
          </>
        }
      >
        {numOfDrafts === 0 ? (
          <NoDraftsContent content={'You have no orders saved as drafts'} />
        ) : (
          <DatatableWrapper body={body} headers={headers}>
            <Table className='table-with-sticky-header' responsive>
              <TableHeader />
              <TableBody />
            </Table>
          </DatatableWrapper>
        )}
      </ExpandableSection>
      {showInfoDialog && (
        <InfoDialog onClose={() => setShowInfoDialog(false)} title='Drafts'>
          <Stack gap={4}>
            <p className='mb-0'>
              When you edit a draft, you will arrive at the beginning of the order form. Your saved form entries will be
              revalidated against our current requirements. Some fields may no longer be valid or exist as our
              requirements evolve.
            </p>
            <p className='mb-0'>
              If the draft is submitted as an order, it will be moved into the Completed Drafts section.
            </p>
          </Stack>
        </InfoDialog>
      )}
    </>
  );
}

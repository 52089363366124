interface IProps {
  content: string;
}

export default function NoDraftsContent({ content }: IProps) {
  return (
    <div className='bg-light w-100 py-5 px-3 rounded-1'>
      <span className='d-flex justify-content-center fw-medium fs-5 lh-xs'>{content}</span>
    </div>
  );
}

import { useMemo } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faLongArrowDown, faLongArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Button, Card } from 'react-bootstrap';

import { SUPPORT_EMAIL } from '@constants/constants';
import useViewportSizing from '@hooks/useViewportSizing';

import OrderStatusCard from './OrderStatusCard';
import type { IOrderProcessStep } from './OrderStatusCard';

import './OrderStatusInfo.scss';

const ORDER_PROCESS_STEPS: IOrderProcessStep[] = [
  {
    step: 'Step One',
    title: 'Awaiting Sample',
    description: (
      <>
        We have received the order and are waiting for a sample.
        <br />
        <br />
        If you requested a kit be shipped to your patient, they should expect the collection kit within 5 business days
        of the order being placed (sometimes packages can be delayed due to unusual circumstances).
      </>
    ),
  },
  {
    step: 'Step Two',
    title: 'Analyzing',
    description: 'We have received the sample. We are now sequencing the sample and interpreting the results.',
  },
  {
    step: 'Completed',
    title: 'Reported',
    description: 'The report is ready and available for download.',
  },
];

export default function OrderStatusInfo() {
  const { isDesktop } = useViewportSizing();

  const nextStepIcon = useMemo(() => (isDesktop ? faLongArrowRight : faLongArrowDown), [isDesktop]);

  return (
    <section className='border-top flex-column flex-md-row flex-lg-column gap-3 gap-lg-4 order-status-info-container'>
      <div className='w-100'>
        <p className='mb-2 text-left fs-6 lh-xs fw-medium'>Order Process Steps</p>
        <div className='d-flex flex-column flex-lg-row gap-3 gap-lg-4 align-items-stretch order-process-steps'>
          <OrderStatusCard {...ORDER_PROCESS_STEPS[0]} />
          <FontAwesomeIcon className='order-process-step-icon' icon={nextStepIcon} fontSize={20} />
          <OrderStatusCard {...ORDER_PROCESS_STEPS[1]} />
          <FontAwesomeIcon className='order-process-step-icon' icon={nextStepIcon} fontSize={20} />
          <OrderStatusCard {...ORDER_PROCESS_STEPS[2]} />
          <FontAwesomeIcon className='order-process-step-icon' icon={faCheck} fontSize={20} />
        </div>
      </div>
      <div className='w-100 d-flex flex-column align-items-center align-items-md-start'>
        <p className='mb-2 text-left fs-6 lh-xs fw-medium align-self-start'>Other Statuses</p>
        <Card className='other-status-card'>
          <Card.Body>
            <Card.Title className='text-left fs-6 fw-semibold'>Canceled</Card.Title>
            <Card.Text className='text-left'>
              <span className='align-middle fs-body'>
                We either received a request to cancel the order or there was an issue with the order that caused us to
                cancel it. If you think this cancellation was a mistake, please contact us at{' '}
              </span>
              <Button
                variant='link'
                className='p-0 border-0 fs-body text-decoration-none fw-medium'
                href={`mailto:${SUPPORT_EMAIL}`}
                target='_blank'
              >
                {SUPPORT_EMAIL}
              </Button>
              .
            </Card.Text>
          </Card.Body>
        </Card>
      </div>
    </section>
  );
}

import { Button, ButtonProps } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';

export default function InfoIconButton(props: ButtonProps) {
  return (
    <Button size='sm' variant='ghost' className='ms-1' {...props}>
      <FontAwesomeIcon icon={faCircleInfo} fontSize={16} />
    </Button>
  );
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Sentry from '@sentry/react';
import axios, { AxiosRequestConfig } from 'axios';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { envInfo } from '@constants/constants';
import useToast from '@contexts/useToast';
import routes from '@router/routes';

import { ERROR_TOAST_BY_CODE } from '@components/Toast/consts';
import { ToastType } from '@customTypes/toast';
import useAuth from './useAuth';

const instance = axios.create({
  baseURL: envInfo.apiurl,
});

export const updateAxiosToken = (token: string | undefined) => {
  if (token) {
    instance.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    delete instance.defaults.headers.common.Authorization;
  }
};

export const AxiosInterceptor = ({ children }: { children: React.ReactNode }) => {
  const navigate = useNavigate();
  const { addToast } = useToast();
  const { pathname } = useLocation();
  const { onInvalidAuth } = useAuth();
  const canRedirectRef = useRef<boolean>(true);
  const activePathnameRef = useRef<string>('');

  const [isSet, setIsSet] = useState(false);

  useEffect(() => {
    const route = routes.find(route => route.path === pathname);
    canRedirectRef.current = !route?.keepOnError;
    activePathnameRef.current = pathname;
  }, [pathname]);

  useEffect(() => {
    const responseInterceptor = instance.interceptors.response.use(
      (res: any) => {
        if (res.data.status_code === 401) {
          onInvalidAuth();
        } else {
          return res;
        }
      },
      (error: any) => {
        const errorCode = error.response?.status ?? 0;

        if (axios.isCancel(error)) {
          console.warn('Request canceled:');
        } else {
          Sentry.captureException(error);

          if (errorCode === 401) {
            onInvalidAuth();
          } else if (canRedirectRef.current && ![408, 500, 503].includes(errorCode)) {
            navigate('/error', {
              state: {
                errorCode: error.response?.status,
              },
            });
          } else {
            const errorData = ERROR_TOAST_BY_CODE[errorCode] ?? { type: ToastType.CUSTOM_ACTIONS };

            /**
             * Do not show a generic error toast in the requisition form
             * because it has a custom error toast which can be used in another projects, as well.
             */
            if (activePathnameRef.current !== '/requisitions/create') {
              addToast(errorData);
            }
          }
        }

        return Promise.reject(error.response);
      },
    );

    setIsSet(true);

    return () => {
      instance.interceptors.response.eject(responseInterceptor);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isSet) return null;

  return <>{children}</>;
};

export default instance;

/**
 * Axios request cancellation blog
 *
 * https://medium.com/gits-apps-insight/front-end-simple-request-cancellation-e4b534762559
 */
export type RequestSaver = Record<string, AbortController>;

export const useRequestSaver = () => {
  // Common State
  const requestSaverList = useRef<RequestSaver>({});

  /**
   * @description Clear all incoming or running request
   *
   * @return {void} void
   */
  const requestSaverAbortAll = (): void => {
    if (Object.keys(requestSaverList.current).length > 0) {
      // Abort incoming request
      Object.values(requestSaverList.current).forEach(requestSaver => {
        if (!requestSaver.signal.aborted) requestSaver.abort();
      });

      // Clear all request from state
      requestSaverList.current = {};
    }
  };

  /**
   * @description Abort some request according identifier
   *
   * @param {string} id
   *
   * @return {void} void
   */
  const requestSaverAbort = (id: string): void => {
    const request = requestSaverList.current?.[id];

    // Check if request exists
    // Check if request is not aborted yet, if not aborted, abort it
    // In the end, clear the aborted request
    if (request && !request.signal.aborted) {
      // Abort the request
      request.abort();

      // Delete aborted request
      delete requestSaverList.current[id];
    }
  };

  /**
   * @description Set cancellation
   *
   * @param {string} id
   *
   * @return {AxiosRequestConfig} AxiosRequestConfig
   */
  const requestSaverSetCancellation = (id: string): AxiosRequestConfig => {
    // Check if theres any request with the same identifier
    // Abort the request if exists
    if (requestSaverList.current?.[id]) requestSaverAbort(id);

    // Make instance of AbortController
    const abortController = new AbortController();

    // Make signal from abort controller to list
    requestSaverList.current[id] = abortController;

    // Return saved signal that stored to state
    return { signal: abortController.signal };
  };

  return {
    requestSaverAbortAll,
    requestSaverAbort,
    requestSaverSetCancellation,
  };
};

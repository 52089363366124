import { Stack } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import InfoDialog from '@components/Modals/InfoDialog';
import { STATUS_ICON_MAPPING } from '@constants/constants';

interface IProps {
  onClose: () => void;
}

export default function OrderInfoDialog({ onClose }: IProps) {
  return (
    <InfoDialog
      onClose={onClose}
      title='Orders and Reports'
      modalProps={{
        id: 'orderInfoDialog',
        className: 'order-info-dialog',
      }}
    >
      <Stack gap={2} className='p-3 pt-2'>
        <p className='fs-6 fw-medium lh-base mb-0'>Each order is associated with one or more reports.</p>
        <div className='fs-body'>
          <p className='mt-1 mb-0'>An order&apos;s status can be:</p>
          <ul className='mb-0 order-status-list'>
            <li>Awaiting Sample</li>
            <li>Analyzing</li>
            <li>Reported</li>
            <li>Canceled</li>
          </ul>
        </div>

        <div className='fs-body'>
          <p className='mb-0 fw-bold'>Results Report†</p>
          <ul className='d-flex flex-column mb-0 results-report-list'>
            {STATUS_ICON_MAPPING.map(item => (
              <li key={item.status}>
                <FontAwesomeIcon aria-label={item.status} icon={item.icon} className='me-2' fontSize={16} />
                {item.status}
              </li>
            ))}
          </ul>
        </div>

        <p className='mb-0 fs-body small'>†Not applicable to the Pharmacogenomics Report.</p>
      </Stack>
    </InfoDialog>
  );
}

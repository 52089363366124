import { useEffect } from 'react';

import { Breadcrumb, Stack } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import useAuth from '@hooks/useAuth';
import useUsers from '@hooks/useUsers';

import DelegateManagement from './components/DelegateManagement/DelegateManagement';
import UserRoleManagement from './components/UserRoleManagement/UserRoleManagement';

import './UserManagement.scss';

export default function UserManagement() {
  const navigate = useNavigate();
  const { isAdmin } = useAuth();
  const { fetchUsers } = useUsers();

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const goBack = () => {
    navigate(-1);
  };

  if (!isAdmin) {
    navigate('/');
    return null;
  }

  return (
    <Stack gap={4} className='layout-single-col-py user-management-container'>
      <Breadcrumb listProps={{ className: 'mb-0' }}>
        <Breadcrumb.Item onClick={goBack}>Return</Breadcrumb.Item>
        <Breadcrumb.Item active>Settings</Breadcrumb.Item>
        <Breadcrumb.Item active>Manage Users</Breadcrumb.Item>
      </Breadcrumb>

      <Stack as='section' className='text-start page-title-section'>
        <h1 className='mb-0 fw-bold page-title'>Manage Users</h1>
        <div className='fs-body fw-normal text-second page-subtitle'>
          <p className='mb-2'>
            Manage users for your organization by assigning delegates to an ordering provider&apos;s clinical team, or
            by assigning organization default delegates to order on behalf of all ordering providers<sup>†</sup> in your
            organization.
          </p>
          <p className='mb-0 fs-xs'>
            <sup>†</sup>An ordering provider is a physician of record with a verified NPI.
          </p>
        </div>
      </Stack>

      <UserRoleManagement />
      <DelegateManagement />
    </Stack>
  );
}

import { ReactNode } from 'react';

import { Variant } from 'react-bootstrap/esm/types';

export enum ToastType {
  BASIC,
  CUSTOM_ACTIONS,
  ACTION_BLOCKED,
  DEFAULT,
}

export interface ToastItem {
  id: string;
  variant?: Variant;
  type?: ToastType;
  content?: string | ReactNode;
  headerTitle?: string;
  errorString?: string;
}

import { Campaign } from '@customTypes/campaign';
import { IAddress, IUsername } from './user';

export interface UserContact {
  phone: string | null;
  email?: string;
  address: IAddress;
  fax?: string | null;
}

export enum OrganizationRole {
  ADMIN = 'ADMIN',
  DELEGATE = 'DELEGATE', // Org Default Delegate
  CLINICIAN = 'CLINICIAN', // Clinician with NPI
}

export interface IClinic {
  name: string;
  clinic_id: string;
  can_order?: boolean;
  roles: OrganizationRole[];
}

export interface Clinician {
  name: IUsername;
  clinic_name: string;
  clinician_id?: string;
  contact_info?: UserContact[];
  campaigns?: Campaign[];
  organizational_roles?: OrganizationRole[];
  npi: string;
  clinics: IClinic[];
}

export enum Sex {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  OTHER = 'OTHER',
}

export interface IExtendedClinician extends Clinician {
  label: string;
  email: string;
  roles: string;
}

export interface IClinicianOption extends Clinician {
  label: string;
  id: string;
}

import { useContext, useState } from 'react';

import { Stack } from 'react-bootstrap';

import InfoIconButton from '@components/Button/InfoIconButton';
import ExpandableSection from '@components/ExpandableSection/ExpandableSection';
import InfoDialog from '@components/Modals/InfoDialog';
import UserManagementForm from '@components/UserManagementForm/UserManagementForm';
import { Clinician } from '@customTypes/clinician';
import { Context } from '@store/store';

export default function ClinicalTeam() {
  const [state, dispatch] = useContext(Context);

  const [showInfoDialog, setShowInfoDialog] = useState<boolean>(false);

  const addSelectedClinician = (user: Clinician) => {
    dispatch({ type: 'ADD_CLINICAL_TEAM_MEMBER', payload: user });
  };

  const removeClinicalTeamMember = (userId: string) => {
    dispatch({ type: 'REMOVE_CLINICAL_TEAM_MEMBER', payload: userId });
  };

  return (
    <>
      <ExpandableSection
        id='clinicalTeam'
        expandableContentId='clinicalTeamContent'
        headerTitle={
          <>
            <span>Clinical Team</span>
            <InfoIconButton onClick={() => setShowInfoDialog(true)} aria-label='Clinical Team Info Button' />
          </>
        }
      >
        <UserManagementForm
          selectedProvider={state.currentClinician}
          selectedUsers={state.clinicalTeam}
          onAddUser={addSelectedClinician}
          onRemoveUser={removeClinicalTeamMember}
          noContentChildren={<p>Use the search field to find and assign a delegate</p>}
        />
      </ExpandableSection>

      {showInfoDialog && (
        <InfoDialog onClose={() => setShowInfoDialog(false)} title='Clinical Team'>
          <Stack gap={4}>
            <p className='mb-0'>
              <span className='fw-bold'>Clinical Team</span> refers to a group of individuals within a healthcare
              organization who are <span className='fw-bold'>assigned by a healthcare provider</span> to have access to
              manage and view all patient orders for which that provider is the{' '}
              <span className='fw-bold'>ordering provider.</span>
            </p>
            <p className='mb-0'>
              Members of the clinical team are delegated specific responsibilities by the provider, enabling them to
              assist with key tasks such as placing orders, tracking results, and managing patient workflows related to
              the provider&apos;s orders.
            </p>
          </Stack>
        </InfoDialog>
      )}
    </>
  );
}

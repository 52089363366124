import { useState } from 'react';

import { DatatableWrapper, TableBody, TableColumnType, TableHeader } from 'react-bs-datatable';
import { Stack, Table } from 'react-bootstrap';

import InfoIconButton from '@components/Button/InfoIconButton';
import ExpandableSection from '@components/ExpandableSection/ExpandableSection';
import InfoDialog from '@components/Modals/InfoDialog';
import NoDraftsContent from '../NoDraftsContent/NoDraftsContent';

interface ICompletedDrafts {
  numOfCompletedDrafts: number;
}

export default function CompletedDrafts({ numOfCompletedDrafts }: ICompletedDrafts) {
  const [showInfoDialog, setShowInfoDialog] = useState<boolean>(false);

  const headers: TableColumnType<{
    orderId: string;
    name: string;
    orderedBy: string;
    orderingProvider: string;
    createdBy: string;
    orderedOn: string;
  }>[] = [
    {
      title: 'Order ID',
      prop: 'orderId',
      isSortable: true,
      alignment: { horizontal: 'left' },
      cell: row => (
        <a href='/' className='text-decoration-none'>
          {row.orderId}
        </a>
      ),
    },
    {
      title: 'Name',
      prop: 'name',
      isSortable: true,
      alignment: { horizontal: 'left' },
    },
    {
      title: 'Ordered By',
      prop: 'orderedBy',
      isSortable: true,
      alignment: { horizontal: 'left' },
    },
    {
      title: 'Ordering Provider',
      prop: 'orderingProvider',
      isSortable: true,
      alignment: { horizontal: 'left' },
    },
    {
      title: 'Created By',
      prop: 'createdBy',
      isSortable: true,
      alignment: { horizontal: 'left' },
    },
    {
      title: 'Ordered On',
      prop: 'orderedOn',
      isSortable: true,
      alignment: { horizontal: 'left' },
    },
  ];

  const body = Array.from(new Array(5), () => {
    return {
      orderId: 'RQ ID here',
      name: 'Name of draft',
      orderedBy: 'Ramida Jiranorraphat, M.D.',
      orderingProvider: 'Ramida Jiranorraphat, M.D.',
      createdBy: 'Claymore Alexander Keen, L.P.N.',
      orderedOn: '01/01/2025',
    };
  });

  return (
    <>
      <ExpandableSection
        id='completedDrafts'
        expandableContentId='completedDraftsContent'
        headerTitle={
          <>
            <span>Completed Drafts ({numOfCompletedDrafts})</span>
            <InfoIconButton onClick={() => setShowInfoDialog(true)} aria-label='Completed Drafts Info Button' />
          </>
        }
        defaultIsCollapsed
      >
        {numOfCompletedDrafts === 0 ? (
          <NoDraftsContent content={'You have no completed draft orders'} />
        ) : (
          <DatatableWrapper body={body} headers={headers}>
            <Table className='table-with-sticky-header' responsive>
              <TableHeader />
              <TableBody />
            </Table>
          </DatatableWrapper>
        )}
      </ExpandableSection>
      {showInfoDialog && (
        <InfoDialog onClose={() => setShowInfoDialog(false)} title='Completed Drafts'>
          <Stack gap={4}>
            <p className='mb-0'>
              Drafts that were submitted as orders will appear here. Each completed draft will have an order ID that
              links to the patient’s page for the order.
            </p>
            <p className='mb-0'>Drafts completed more than 30 days ago are not listed.</p>
          </Stack>
        </InfoDialog>
      )}
    </>
  );
}

import { lazy, Suspense, useMemo } from 'react';

import { Container, Nav, Navbar } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as FullLogoSvg } from '@assets/images/full_logo.svg';
import { MANAGE_USERS, MY_DELEGATES } from '@constants/routes';
import useAuth from '@hooks/useAuth';
import useViewportSizing from '@hooks/useViewportSizing';

import { IGroupSetting } from './GroupSettings';

import './HeaderNav.scss';

const LazyMobileDropdown = lazy(() => import('./MobileDropdown'));
const LazyDesktopNav = lazy(() => import('./DesktopNav'));
const LazyAccessBanner = lazy(() => import('./AccessBanner'));

const Header = () => {
  const { onLogin, isAdmin, isOrderingProvider, hasPartnershipOrdering, isLoggedIn } = useAuth();
  const navigate = useNavigate();
  const { isMobile } = useViewportSizing();

  const listOfSettings: IGroupSetting[] = useMemo(() => {
    const accountSettings: IGroupSetting = {
      title: isMobile ? 'Account Settings' : '',
      settings: [],
    };

    if (isOrderingProvider) {
      accountSettings.settings.push({
        href: MY_DELEGATES,
        label: 'My Delegates',
      });
    }

    const results = [accountSettings];

    if (isAdmin) {
      results.push({
        title: 'Admin Settings',
        settings: [
          {
            label: 'Manage Users',
            href: MANAGE_USERS,
          },
        ],
      });
    }

    return results.filter(item => item.settings.length > 0);
  }, [isAdmin, isMobile, isOrderingProvider]);
  const canShowAccessBanner = useMemo(
    () => isLoggedIn && (!isMobile || hasPartnershipOrdering),
    [isLoggedIn, isMobile, hasPartnershipOrdering],
  );

  const navigateToHome = () => {
    navigate('/');
  };

  return (
    <Suspense>
      <header
        // eslint-disable-next-line max-len
        className={`fixed-top ${canShowAccessBanner ? 'has-access-banner' : ''} ${hasPartnershipOrdering ? 'has-partnership-ordering' : ''}`}
      >
        {canShowAccessBanner && <LazyAccessBanner listOfSettings={listOfSettings} />}

        <Navbar expand='md' className='bg-white border-bottom border-cyan border-2 box-shadow-100 py-md-3 main-navbar'>
          <Container fluid='xxl'>
            <Navbar.Brand onClick={navigateToHome} className='p-0'>
              <FullLogoSvg height={isMobile ? 30 : 40} />
            </Navbar.Brand>
            {isMobile && isLoggedIn && <Navbar.Toggle className='toggle-btn' />}
            {isMobile && !isLoggedIn && <Nav.Link onClick={() => onLogin()}>Login</Nav.Link>}
            <Navbar.Collapse className='justify-content-end mb-n2 mt-4 mb-md-0 mt-md-0'>
              {isMobile ? <LazyMobileDropdown listOfSettings={listOfSettings} /> : <LazyDesktopNav />}
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>
    </Suspense>
  );
};

export default Header;

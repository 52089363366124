import { Button, Modal, ModalProps } from 'react-bootstrap';

import { ICustomButtonProps } from '@customTypes/common';

interface IProps {
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  description: string;
  modalProps?: ModalProps;
  cancelBtnProps?: ICustomButtonProps;
}

export default function ConfirmDialog({
  onClose,
  onConfirm,
  title,
  description,
  modalProps = {},
  cancelBtnProps = {},
}: IProps) {
  return (
    <Modal show onHide={onClose} id='confirm-dialog' aria-labelledby='confirm-modal-title' {...modalProps}>
      <Modal.Header closeButton>
        <Modal.Title as='h4' id='confirm-modal-title' className='fs-5 fw-medium lh-sm'>
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className='mb-0'>{description}</p>
      </Modal.Body>
      <Modal.Footer className='gap-3'>
        <Button variant='outline-primary' onClick={onConfirm} className='flex-grow-1'>
          Yes
        </Button>
        <Button variant='primary' onClick={onClose} className='flex-grow-1' {...cancelBtnProps}>
          No
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

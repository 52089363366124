import { AppActions, AppState } from '@customTypes/app';

const Reducer = (state: AppState, action: AppActions): AppState => {
  switch (action.type) {
    case 'SET_REQUISITIONS':
      return {
        ...state,
        requisitions: action.payload,
      };
    case 'SET_NEW_REQUISITION':
      return {
        ...state,
        newRequisition: action.payload,
      };
    case 'SET_CURRENT_CLINICIAN':
      /**
       * Assuem there will always be at least one clinics in the clinician object
       * and the first clinic id will be an organization id
       */
      return {
        ...state,
        currentClinician: action.payload,
        clinicId: action.payload.clinics[0].clinic_id,
      };
    case 'SET_KEYCLOAK':
      return {
        ...state,
        keycloak: action.payload,
      };
    case 'SET_FORMATTED_PRODUCTS_LIST':
      return {
        ...state,
        formattedProductsList: action.payload,
      };
    case 'SET_CLINICIAN_FETCHING':
      return {
        ...state,
        isFetchingClinician: action.payload,
      };
    case 'SET_REQUISITION_TASK_FORM':
      return {
        ...state,
        requisitionTaskForm: action.payload,
      };
    case 'SET_PATIENT_DETAIL':
      return {
        ...state,
        patientsMap: {
          ...state.patientsMap,
          [action.payload.patientId]: action.payload.result,
        },
      };
    case 'SET_CLINICAL_TEAM':
      return {
        ...state,
        clinicalTeam: action.payload,
      };
    case 'ADD_CLINICAL_TEAM_MEMBER':
      return {
        ...state,
        clinicalTeam: state.clinicalTeam.concat(action.payload),
      };
    case 'REMOVE_CLINICAL_TEAM_MEMBER':
      return {
        ...state,
        clinicalTeam: state.clinicalTeam.filter(item => item.clinician_id !== action.payload),
      };
    case 'SET_USERS':
      return {
        ...state,
        users: action.payload,
      };
    case 'UPDATE_USER':
      return {
        ...state,
        users: state.users.map(item => (item.clinician_id === action.payload.clinician_id ? action.payload : item)),
      };
    default:
      return state;
  }
};

export default Reducer;

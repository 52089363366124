import { Highlighter, Typeahead, TypeaheadComponentProps } from 'react-bootstrap-typeahead';

import { IClinicianOption } from '@customTypes/clinician';

export default function UserTypeahead({ onChange, ...otherProps }: TypeaheadComponentProps) {
  const onTypeaheadChange = (value: unknown) => {
    const formattedValue = (value as IClinicianOption[]).map(item => ({
      ...item,
      label: item.contact_info?.[0].email ? `${item.label} (${item.contact_info?.[0].email})` : item.label,
    }));

    onChange?.(formattedValue);
  };

  return (
    <Typeahead
      placeholder='Begin typing'
      onChange={onTypeaheadChange}
      renderMenuItemChildren={(option, { text }) => {
        const clinicianOption = option as IClinicianOption;

        return (
          <>
            <Highlighter search={text}>{clinicianOption.label}</Highlighter>
            <span className='fs-body small d-block'> {clinicianOption.contact_info?.[0].email}</span>
          </>
        );
      }}
      {...otherProps}
    />
  );
}

import { lazy, ReactNode, Suspense, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { orderBy } from 'lodash';
import { Button, Form, InputGroup, ListGroup } from 'react-bootstrap';

import UserTypeahead from '@components/Typeahead/UserTypeahead';
import useToast from '@contexts/useToast';
import type { Clinician, IClinicianOption } from '@customTypes/clinician';
import { ToastType } from '@customTypes/toast';
import { getClinicianName, getClinicianNameWithLastNameFirst } from '@helpers/clinician';
import { addUserToClinicalTeam, removeUserToClinicalTeam } from '@store/actions';
import { Context } from '@store/store';

import './UserManagementForm.scss';

interface IProps {
  selectedUsers: Clinician[];
  selectedProvider?: Clinician;
  noContentChildren: ReactNode;
  onAddUser: (user: Clinician) => void;
  onRemoveUser: (userId: string) => void;
}

const LazyConfirmDialog = lazy(() => import('@components/Modals/ConfirmDialog'));

export default function UserManagementForm({
  selectedUsers,
  selectedProvider,
  noContentChildren,
  onAddUser,
  onRemoveUser,
}: IProps) {
  const [state] = useContext(Context);
  const { addToast } = useToast();

  const [saving, setSaving] = useState(false);
  const addTeamBtnRef = useRef<HTMLButtonElement | null>(null);
  const providerId: string = useMemo(
    () => selectedProvider?.clinician_id || state.currentClinician?.clinician_id || '',
    [selectedProvider, state.currentClinician],
  );
  const providerIsLoggedinUser = useMemo(
    () => selectedProvider?.clinician_id === state.currentClinician?.clinician_id,
    [selectedProvider, state.currentClinician],
  );
  const [selectedClinician, setSelectedClinician] = useState<IClinicianOption[]>([]);
  const [clinicianToRemove, setClinicianToRemove] = useState<IClinicianOption | null>(null);

  const formattedUsers = useMemo(() => {
    const result = selectedUsers.map(item => ({
      ...item,
      label: getClinicianNameWithLastNameFirst(item),
    })) as IClinicianOption[];

    return orderBy(result, ['label'], ['asc']);
  }, [selectedUsers]);

  const options: IClinicianOption[] = useMemo(() => {
    const usersToExclude = [...selectedUsers];

    if (selectedProvider) {
      usersToExclude.push(selectedProvider);
    }
    const userIdsToExclude = usersToExclude.map(delegate => delegate.clinician_id);
    const result = state.users
      .map(delegate => ({
        ...delegate,
        label: getClinicianNameWithLastNameFirst(delegate),
        id: delegate.clinician_id ?? '',
      }))
      .filter(delegate => !userIdsToExclude.includes(delegate.clinician_id));

    return orderBy(result, ['label'], ['asc']);
  }, [selectedUsers, selectedProvider, state.users]);

  useEffect(() => {
    if (selectedClinician.length && addTeamBtnRef.current) {
      addTeamBtnRef.current.focus();
    }
  }, [selectedClinician]);

  const addSelectedClinician = async () => {
    try {
      setSaving(true);

      await addUserToClinicalTeam(state.clinicId!, providerId, selectedClinician[0].id);
      onAddUser(selectedClinician[0]);
      setSelectedClinician([]);
      addToast({
        // eslint-disable-next-line max-len
        headerTitle: `Delegate added to ${selectedProvider && !providerIsLoggedinUser ? `${getClinicianName(selectedProvider)}'s clinical team` : 'your list'}`,
        type: ToastType.DEFAULT,
      });
    } catch (err) {
      console.error('Error on adding a new user: ', err);
    } finally {
      setSaving(false);
    }
  };

  const removeClinicalTeamMember = async () => {
    try {
      if (clinicianToRemove?.clinician_id) {
        setSaving(true);

        await removeUserToClinicalTeam(state.clinicId!, providerId, clinicianToRemove!.clinician_id!);
        onRemoveUser(clinicianToRemove!.clinician_id);
        addToast({ headerTitle: 'Delegate removed', type: ToastType.DEFAULT });
      }
      setClinicianToRemove(null);
    } catch (err) {
      console.error('Error on removing a user: ', err);
    } finally {
      setSaving(false);
    }
  };

  return (
    <Suspense>
      <Form className='add-delegate-form'>
        <Form.Group className='d-flex flex-column align-items-start'>
          <Form.Label>Search for a new delegate</Form.Label>
          <InputGroup>
            <UserTypeahead
              id='delegateSearchTypeahead'
              options={options}
              selected={selectedClinician}
              onChange={value => setSelectedClinician(value as IClinicianOption[])}
            />
            <Button
              ref={addTeamBtnRef}
              disabled={!selectedClinician.length || saving}
              variant={selectedClinician.length ? 'primary' : 'outline-secondary'}
              onClick={addSelectedClinician}
            >
              Add to team
            </Button>
          </InputGroup>
        </Form.Group>
      </Form>
      {formattedUsers.length > 0 ? (
        <ListGroup as='ul' className='selected-users'>
          {formattedUsers.map(clinician => (
            <ListGroup.Item key={clinician.clinician_id} as='li'>
              <div className='d-flex flex-row justify-content-between align-items-center'>
                <div>
                  <p className='mb-0 fs-6 fw-semibold lh-xs'>{clinician.label}</p>
                  <span className='field-value'>{clinician.contact_info?.[0]?.email ?? ''}</span>
                </div>
                <Button
                  size='sm'
                  variant='outline-dark'
                  disabled={saving}
                  onClick={() => setClinicianToRemove(clinician)}
                >
                  Remove
                </Button>
              </div>
            </ListGroup.Item>
          ))}
        </ListGroup>
      ) : (
        <div className='bg-tertiary no-content'>{noContentChildren}</div>
      )}

      {!!clinicianToRemove && (
        <LazyConfirmDialog
          title='Confirm Remove'
          // eslint-disable-next-line max-len
          description={`Do you want to remove ${getClinicianName(clinicianToRemove)} from ${selectedProvider && !providerIsLoggedinUser ? `${getClinicianName(selectedProvider, false)}'s` : 'your'} Clinical Team?`}
          onClose={() => setClinicianToRemove(null)}
          onConfirm={removeClinicalTeamMember}
        />
      )}
    </Suspense>
  );
}

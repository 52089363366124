import { forwardRef, useMemo } from 'react';
import { ListGroup } from 'react-bootstrap';

import { Requisition } from '@customTypes/requisition';
import { useRequisition } from '@hooks/useRequisition';

import './RequisitionReceiptContent.scss';

interface IProps {
  requisition: Requisition;
}

interface RequisitionFieldToRender {
  label: string;
  value: string | string[];
  unordered?: boolean;
  dataTestId?: string;
}

const RequisitionReceiptContent = forwardRef<HTMLDivElement, IProps>(({ requisition }, ref) => {
  const { clinicians, patientName, requisitionId, requisitionDate, orders } = useRequisition(requisition);

  const fieldsToRender: RequisitionFieldToRender[] = useMemo(() => {
    if (!requisition) return [];

    return [
      {
        label: 'Order ID',
        value: requisitionId,
        dataTestId: 'requisitionNumber',
      },
      {
        label: 'Order Date',
        value: requisitionDate,
        dataTestId: 'orderDate',
      },
      {
        label: clinicians.length > 1 ? 'Clinicians' : 'Clinician',
        value: clinicians.map(clinician => clinician.formattedName),
        dataTestId: 'clinicians',
      },
      {
        label: 'Patient',
        value: patientName,
        dataTestId: 'patient',
      },
      {
        label: orders?.length > 1 ? 'Tests' : 'Test',
        value: orders.map(order => order.product_name),
        unordered: true,
        dataTestId: 'tests',
      },
    ];
  }, [requisition, clinicians, requisitionId, patientName, requisitionDate, orders]);

  return (
    <div ref={ref} className='requisition-receipt-content-wrapper'>
      <ListGroup as='ul' className='mx-auto requisition-receipt-content'>
        {fieldsToRender.map(field => {
          const isArray = Array.isArray(field.value);
          const unordered = field.unordered && field.value?.length > 1;

          return (
            <ListGroup.Item key={field.label} as='li' data-testid={field.dataTestId}>
              <p className='mb-0 fs-6 fw-semibold field-label'>{field.label}</p>
              {!isArray && <span className='field-value'>{field.value}</span>}
              {isArray && unordered && (
                <ul className='mb-2 field-value unordered-receipt-item' data-testid='unorderedField'>
                  {(field.value as string[]).map(item => (
                    <li key={item}>{item}</li>
                  ))}
                </ul>
              )}
              {isArray && !unordered && (
                <div className='field-value'>
                  {(field.value as string[]).map(item => (
                    <span key={item} className='d-block'>
                      {item}
                    </span>
                  ))}
                </div>
              )}
            </ListGroup.Item>
          );
        })}
      </ListGroup>
    </div>
  );
});

RequisitionReceiptContent.displayName = 'RequisitionReceiptContent';
export default RequisitionReceiptContent;

import { useState } from 'react';

import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';

import ExpandableSection from '@components/ExpandableSection/ExpandableSection';
import { Requisition } from '@customTypes/requisition';

import PatientDetailOrder from './PatientDetailOrder';
import OrderInfoDialog from './OrderInfoDialog';

import './PatientDetailOrders.scss';

interface IProps {
  requisitions: Requisition[];
}

const PatientDetailOrders = ({ requisitions }: IProps) => {
  const [showInfoDialog, setShowInfoDialog] = useState(false);

  return (
    <>
      <ExpandableSection
        id='orders'
        expandableContentId='ordersContent'
        headerTitle={
          <>
            <span>Orders ({requisitions.length})</span>
            <Button
              size='sm'
              variant='ghost'
              className='ms-1'
              onClick={() => setShowInfoDialog(true)}
              aria-label='Patient Detail Order Info Button'
            >
              <FontAwesomeIcon icon={faCircleInfo} fontSize={16} />
            </Button>
          </>
        }
      >
        {requisitions.map(requisition => (
          <PatientDetailOrder key={requisition.requisition_id} requisition={requisition} />
        ))}
      </ExpandableSection>

      {showInfoDialog && <OrderInfoDialog onClose={() => setShowInfoDialog(false)} />}
    </>
  );
};

export default PatientDetailOrders;

import { useEffect } from 'react';

import { Breadcrumb, Stack } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import useAuth from '@hooks/useAuth';
import useUsers from '@hooks/useUsers';

import ClinicalTeam from './components/ClinicalTeam/ClinicalTeam';
import OrgDefaultDelegates from './components/OrgDefaultDelegates/OrgDefaultDelegates';

import './MyDelegates.scss';

export default function MyDelegates() {
  const navigate = useNavigate();
  const { fetchUsers } = useUsers();
  const { isOrderingProvider } = useAuth();

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const goBack = () => {
    navigate(-1);
  };

  if (!isOrderingProvider) {
    navigate('/');
    return null;
  }

  return (
    <Stack gap={4} className='layout-single-col-py my-delegates-container'>
      <Breadcrumb listProps={{ className: 'mb-0' }}>
        <Breadcrumb.Item onClick={goBack}>Return</Breadcrumb.Item>
        <Breadcrumb.Item active>Settings</Breadcrumb.Item>
        <Breadcrumb.Item active>My Delegates</Breadcrumb.Item>
      </Breadcrumb>

      <Stack as='section' className='text-start page-title-section'>
        <h1 className='mb-0 fw-bold page-title'>My Delegates</h1>
        <div className='fs-body fw-normal text-second page-subtitle'>
          <p className='mb-1'>
            MyOme aims to simplify the complex, so we&apos;ve made managing access to orders flexible for your
            clinic&apos;s workflow.
          </p>
          <ul className='mb-0'>
            <li>
              <strong>Clinical Teams:</strong> Individuals assigned by a provider to manage and view orders where the
              provider is the ordering provider.
            </li>
            <li>
              <strong>Organization Default Delegates:</strong> Individuals assigned by an organization administrator to
              manage and view all patient orders across the organization.
            </li>
          </ul>
        </div>
      </Stack>

      <ClinicalTeam />
      <OrgDefaultDelegates />
    </Stack>
  );
}

export enum OrderStatus {
  CANCELED = 'CANCELED',
  DENIED = 'DENIED',
  FAILED = 'FAILED',
  SUBMITTED = 'SUBMITTED',
  APPROVED = 'APPROVED',
  AWAITING_SAMPLE = 'AWAITING_SAMPLE',
  ANALYZING = 'ANALYZING',
  CLINICIAN_REVIEW = 'CLINICIAN_REVIEW',
  COMPLETED = 'COMPLETED',
  REPORTED = 'REPORTED',
}

export enum OrderResultFinding {
  POSITIVE = 'POSITIVE',
  NEGATIVE = 'NEGATIVE',
  AVERAGE_RISK = 'AVERAGE_RISK',
  ELEVATED_RISK = 'ELEVATED_RISK',
  MINIMAL_IMPACT = 'MINIMAL_IMPACT',
  ALTERED_DOSE = 'ALTERED_DOSE',
  MAJOR_IMPACT = 'MAJOR_IMPACT',
  CONTRAINDICATED = 'CONTRAINDICATED',
  HIGH_RISK = 'HIGH_RISK',
  INTERMEDIATE_RISK = 'INTERMEDIATE_RISK',
  BORDERLINE_RISK = 'BORDERLINE_RISK',
  LOW_RISK = 'LOW_RISK',
}

export interface OrderResult {
  description: string;
  uri: string;
  mimetype: string;
}

export interface ToplineResult {
  finding: OrderResultFinding;
  text: string;
}

export interface Order {
  created_at: string;
  order_id: string;
  product_id: string;
  product_name: string;
  status: OrderStatus;
  results?: ToplineResult[];
  reported_at?: string;
  canceled_at?: string;
}

export interface ExtendedOrder extends Order {
  isReported: boolean;
}

import { useContext, useMemo, useState } from 'react';
import { Card, Collapse, Stack } from 'react-bootstrap';
import { faCircleInfo, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

import IconButton from '@components/Button/IconButton';
import Placeholder from '@components/Placeholder/Placeholder';
import { OrderStatus } from '@customTypes/order';
import { getRequisitionStatusKey } from '@helpers/requisition';
import { useRequisition } from '@hooks/useRequisition';
import { Context } from '@store/store';

import OrderStatusInfo from './components/OrderStatusInfo/OrderStatusInfo';
import RequisitionTable from './components/RequisitionTable/RequisitionTable';

import './Requisitions.scss';

export default function Requisitions() {
  const [state] = useContext(Context);
  const { loadRequisitions, isRequisitionsLoading, requisitionsLoadError } = useRequisition();

  const [showPatientName, setShowPatientName] = useState(true);
  const [showOrderStatusInfo, setShowOrderStatusInfo] = useState(false);

  const orderStatus: Array<{ title: string; value: number }> = useMemo(() => {
    const requisitions = state.requisitions || [];
    const outstandingOrders = [];
    const reportedOrders = [];

    requisitions.forEach(requisition => {
      const statusKey = getRequisitionStatusKey(requisition);

      if (statusKey) {
        if ([OrderStatus.REPORTED, OrderStatus.COMPLETED].includes(statusKey)) {
          reportedOrders.push(requisition);
        } else if (![OrderStatus.CANCELED, OrderStatus.DENIED, OrderStatus.FAILED].includes(statusKey)) {
          outstandingOrders.push(requisition);
        }
      }
    });

    return [
      {
        title: 'Total Orders',
        value: requisitions.length,
      },
      {
        title: 'Outstanding Orders',
        value: outstandingOrders.length,
      },
      {
        title: 'Reported Orders',
        value: reportedOrders.length,
      },
    ];
  }, [state.requisitions]);

  return (
    <Stack gap={4} className='layout-single-col-py' data-testid='requisitionsPage'>
      <section className='d-flex flex-column text-start rounded-1 bg-white box-shadow-50 order-report-section'>
        <h1 className='fw-bold page-title'>Your orders and reports</h1>
        <p className='mt-md-1 fs-body fw-normal text-second mb-0 page-subtitle'>
          View and search orders to download reports and access patient information
        </p>
        <div className='p-0'>
          <div className='d-flex flex-column flex-sm-row mt-3 mt-md-4 justify-content-center order-cards'>
            {orderStatus.map(status => (
              <Card key={status.title} className='fw-bold bg-white order-status border-1'>
                <Card.Body className='py-2 py-md-3'>
                  {isRequisitionsLoading && (
                    <div className='d-flex flex-column gap-3 order-status--title'>
                      <Placeholder size='xl' />
                      <Placeholder />
                    </div>
                  )}
                  {!isRequisitionsLoading && (
                    <>
                      <h2 className={`fs-1 fw-bold order-status--value ${requisitionsLoadError ? 'error' : ''}`}>
                        {requisitionsLoadError ? '...' : status.value}
                      </h2>
                      <p className='fs-5 mb-0 order-status--title'>{status.title}</p>
                    </>
                  )}
                </Card.Body>
              </Card>
            ))}
          </div>
        </div>
        <p className='mt-3 mt-md-4 fs-xs fw-normal text-second mb-0 text-center'>
          Outstanding and Reported counts do not include canceled, failed, or denied orders. Their sum is not included
          in the Total count.
        </p>
      </section>
      {isRequisitionsLoading ? (
        <div className='d-flex gap-12'>
          <Placeholder size='sm' />
          <Placeholder size='sm' />
        </div>
      ) : (
        <div className='d-flex gap-12'>
          <IconButton
            variant='outline-primary'
            icon={showPatientName ? faEye : faEyeSlash}
            size='sm'
            onClick={() => setShowPatientName(showPatientName => !showPatientName)}
            role='toolbar'
            aria-label='button toggle for patient name privacy'
            className='align-self-start patient-toggle-btn'
            disabled={!!requisitionsLoadError}
          >
            Patient name
          </IconButton>
          <IconButton
            variant={showOrderStatusInfo ? 'dark' : 'outline-dark'}
            icon={faCircleInfo}
            size='sm'
            onClick={() => setShowOrderStatusInfo(showOrderStatusInfo => !showOrderStatusInfo)}
            role='toolbar'
            className='align-self-start'
          >
            Order Statuses
          </IconButton>
        </div>
      )}
      <Collapse in={showOrderStatusInfo} className='order-status-collapse'>
        <div>
          <OrderStatusInfo />
        </div>
      </Collapse>

      <RequisitionTable
        loading={isRequisitionsLoading}
        loadingError={requisitionsLoadError}
        showPatientName={showPatientName}
        data={state.requisitions || []}
        onRetry={loadRequisitions}
      />
    </Stack>
  );
}

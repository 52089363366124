import { ReactNode } from 'react';

import { Button, Modal, ModalProps, ModalTitleProps } from 'react-bootstrap';

import { ICustomButtonProps } from '@customTypes/common';

interface IProps {
  onClose: () => void;
  children: ReactNode;
  title: string;
  modalProps?: ModalProps;
  actionBtnProps?: ICustomButtonProps;
  titleProps?: ModalTitleProps;
}

export default function InfoDialog({
  title,
  children,
  onClose,
  modalProps = {},
  actionBtnProps = {},
  titleProps = {},
}: IProps) {
  return (
    <Modal show onHide={onClose} id='info-dialog' aria-labelledby='infoModalTitle' {...modalProps}>
      <Modal.Header closeButton>
        <Modal.Title as='h4' id='infoModalTitle' className='fs-5 fw-medium lh-sm' {...titleProps}>
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='d-flex justify-content-center'>{children}</Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={onClose} {...actionBtnProps}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

import { useMemo } from 'react';

import { Button } from 'react-bootstrap';

import ExpandableSection from '@components/ExpandableSection/ExpandableSection';
import { SUPPORT_EMAIL } from '@constants/constants';
import { Rerequisition } from '@customTypes/requisition';

import NoDetailContent from '../NoDetailContent/NoDetailContent';
import NewReportCard from './NewReportCard';

import './PatientDetailReports.scss';

interface IProps {
  rerequisition: Rerequisition | null;
}

export default function PatientDetailReports({ rerequisition }: IProps) {
  const noReports = useMemo(() => {
    if (!rerequisition) return false;
    if (rerequisition.eligible_products.length) return false;
    if (rerequisition.eligible_campaign_products?.campaign) return false;

    return true;
  }, [rerequisition]);

  return (
    <ExpandableSection
      id='newReports'
      expandableContentId='newReportsContent'
      headerTitle={'New Reports Available for Ordering'}
    >
      {!!rerequisition && !noReports && (
        <div className='d-flex flex-column flex-md-row gap-3 gap-lg-4 new-reports'>
          {!!rerequisition.eligible_campaign_products?.campaign && (
            <NewReportCard {...rerequisition.eligible_campaign_products} />
          )}
          {!!rerequisition.eligible_products.length && <NewReportCard products={rerequisition.eligible_products} />}
        </div>
      )}
      {noReports && <NoDetailContent content='There are no new reports available at this time.' />}
      {!rerequisition && (
        <NoDetailContent content='Ordering new reports is only available once an existing order is complete.' />
      )}
      <p className='text-left fs-body mb-0'>
        <span className='d-block'>
          The list of available reports is based off your patient and their previous orders, and no additional sample
          will be required.
        </span>
        <span className='d-block'>
          <span className='d-inline-block align-middle'>If you have questions, please contact</span>
          <Button
            variant='link'
            className='p-0 border-0 ms-1 fs-body text-decoration-none'
            href={`mailto:${SUPPORT_EMAIL}`}
            target='_blank'
          >
            {SUPPORT_EMAIL}.
          </Button>
        </span>
      </p>
    </ExpandableSection>
  );
}

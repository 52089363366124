import { useContext, useMemo, useState } from 'react';

import { orderBy } from 'lodash';
import { ListGroup, Stack } from 'react-bootstrap';

import InfoIconButton from '@components/Button/InfoIconButton';
import ExpandableSection from '@components/ExpandableSection/ExpandableSection';
import InfoDialog from '@components/Modals/InfoDialog';
import { getClinicianNameWithLastNameFirst } from '@helpers/clinician';
import { Context } from '@store/store';

export default function OrgDefaultDelegates() {
  const [state] = useContext(Context);

  const [showInfoDialog, setShowInfoDialog] = useState<boolean>(false);

  const clinicians = useMemo(() => {
    const result = state.orgDefaultDelegates.map(item => ({
      ...item,
      name: getClinicianNameWithLastNameFirst(item),
    }));

    return orderBy(result, ['name'], ['asc']);
  }, [state.orgDefaultDelegates]);

  return (
    <>
      <ExpandableSection
        id='orgDefaultDelegates'
        expandableContentId='orgDefaultDelegatesContent'
        headerTitle={
          <>
            <span className='text-left'>Organization Default Delegates</span>
            <InfoIconButton
              onClick={() => setShowInfoDialog(true)}
              aria-label='Organization Default Delegates Info Button'
            />
          </>
        }
      >
        {clinicians.length > 0 ? (
          <ListGroup as='ul'>
            {clinicians.map(clinician => (
              <ListGroup.Item key={clinician.clinician_id} as='li'>
                <p className='mb-0 fs-6 fw-semibold lh-xs'>{clinician.name}</p>
                <span className='field-value'>{clinician.contact_info?.[0]?.email}</span>
              </ListGroup.Item>
            ))}
          </ListGroup>
        ) : (
          <div className='bg-tertiary no-content'>
            <p>There are no organization default delegates assigned at this time</p>
          </div>
        )}
        <p className='mb-0 text-left fs-body'>
          Contact your organization admin with questions about organization default delegates.
        </p>
      </ExpandableSection>

      {showInfoDialog && (
        <InfoDialog onClose={() => setShowInfoDialog(false)} title='Organization Default Delegates'>
          <Stack gap={4}>
            <p className='mb-0'>
              <span className='fw-bold'>Organization default delegates</span> are individuals within a healthcare
              organization who are <span className='fw-bold'>assigned by an organization administrator</span> to have
              access to manage and view <span className='fw-bold'>all patient orders</span> and related tasks across the
              entire organization.
            </p>
            <p className='mb-0'>
              These default delegates are specifically granted this broad access ensuring streamlined management of
              orders, results, and other related workflows.
            </p>
          </Stack>
        </InfoDialog>
      )}
    </>
  );
}

import { Clinician } from '@customTypes/clinician';

export const getClinicianName = (clinician?: Clinician | null, withCredential = true) => {
  const name = clinician?.name;

  if (!name) return '';

  const cred = name.credentials;
  let formatted = `${name.first} ${name.middle ? name.middle + ' ' : ''}${name.last}`;

  if (cred && withCredential) formatted += ', ' + cred;

  return formatted;
};

export const getClinicianNameWithLastNameFirst = (clinician?: Clinician | null) => {
  const name = clinician?.name;

  if (!name) return '';

  const cred = name.credentials;
  let formatted = `${name.last}, ${name.first}${name.middle ? ' ' + name.middle : ''}`;

  if (cred) formatted += ', ' + cred;

  return formatted;
};

export const setOrganizationRoles = (clinician: Clinician): Clinician => {
  const organizationRoles = clinician.clinics?.[0].roles || [];

  return {
    ...clinician,
    organizational_roles: organizationRoles,
  };
};

export const setOrganizationRoleFromClinic = (clinicians: Clinician[], clinicId: string): Clinician[] => {
  return clinicians.map(clinician => {
    const clinic = clinician.clinics.find(item => item.clinic_id === clinicId);
    const organizationRoles = clinic?.roles || [];

    return {
      ...clinician,
      organizational_roles: organizationRoles,
    };
  });
};

import ConfirmDialog from '@components/Modals/ConfirmDialog';

interface CancelConfirmDialogProps {
  onClose: () => void;
  onConfirm: () => void;
  isOrderNewReport?: boolean;
}

export default function CancelConfirmDialog({
  onClose,
  onConfirm,
  isOrderNewReport = false,
}: CancelConfirmDialogProps) {
  return (
    <ConfirmDialog
      title='Cancel and Return'
      description={`Do you want to return to the ${isOrderNewReport ? 'Patient Detail' : 'Orders and Reports'} page? 
      Your order form progress will be lost.`}
      onClose={onClose}
      onConfirm={onConfirm}
      modalProps={{
        'aria-labelledby': 'cancel-return-modal-title',
        'data-testid': 'requisitionCancelConfirmDialog',
        id: 'cancel-return-confirm-dialog',
      }}
      cancelBtnProps={{
        'data-testid': 'createRequisitionCloseConfirmBtn',
      }}
    />
  );
}

import { Button, Modal, ModalProps, ModalTitleProps } from 'react-bootstrap';

import { ICustomButtonProps } from '@customTypes/common';

interface IProps {
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  children: React.ReactNode;
  modalProps?: ModalProps;
  cancelBtnProps?: ICustomButtonProps;
  confirmBtnProps?: ICustomButtonProps;
  titleProps?: ModalTitleProps;
}

export default function CommonDialog({
  onClose,
  onConfirm,
  title,
  children,
  modalProps = {},
  cancelBtnProps = {},
  confirmBtnProps = {},
  titleProps = {},
}: IProps) {
  return (
    <Modal show onHide={onClose} id='common-dialog' aria-labelledby='common-modal-title' {...modalProps}>
      <Modal.Header closeButton>
        <Modal.Title as='h4' id='common-modal-title' className='fs-5 fw-medium lh-sm' {...titleProps}>
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer className='gap-2'>
        <Button variant='secondary' className='mb-0' onClick={onClose} {...cancelBtnProps}>
          {cancelBtnProps?.children ? cancelBtnProps.children : 'Cancel'}
        </Button>
        <Button variant='primary' className='mb-0' onClick={onConfirm} {...confirmBtnProps}>
          {confirmBtnProps?.children ? confirmBtnProps.children : 'Yes'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

import { intersection } from 'lodash';
import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { faDiamondExclamation } from '@fortawesome/pro-solid-svg-icons';

import { ResultsType } from '@customTypes/app';
import { OrderResultFinding, ToplineResult } from '@customTypes/order';

export const getResultType = (results: ToplineResult[]): ResultsType => {
  const findings = results.map(result => result.finding);

  if (
    intersection(findings, [
      OrderResultFinding.POSITIVE,
      OrderResultFinding.ELEVATED_RISK,
      OrderResultFinding.HIGH_RISK,
      OrderResultFinding.INTERMEDIATE_RISK,
    ]).length
  ) {
    return ResultsType.ACTIONABLE_RESULT;
  }
  if (
    intersection(findings, [
      OrderResultFinding.NEGATIVE,
      OrderResultFinding.AVERAGE_RISK,
      OrderResultFinding.BORDERLINE_RISK,
      OrderResultFinding.LOW_RISK,
    ]).length
  ) {
    return ResultsType.AVERAGE_RISK_OR_NEGATIVE;
  }
  return ResultsType.NO_DATA_AVAILABLE;
};

export const getIconByResult = (result: ResultsType) => {
  let icon;

  if (result === ResultsType.ACTIONABLE_RESULT) {
    icon = faDiamondExclamation;
  }
  if (result === ResultsType.AVERAGE_RISK_OR_NEGATIVE) {
    icon = faCircle;
  }

  return icon;
};

interface IProps {
  content: string;
}

export default function NoDetailContent({ content }: IProps) {
  return (
    <div className='bg-light w-100 no-patient-detail-content'>
      <span className='d-flex justify-content-center fw-semibold fs-5 lh-xs'>{content}</span>
    </div>
  );
}

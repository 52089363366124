import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { v4 as uuidv4 } from 'uuid';
import { ToastContainer } from 'react-bootstrap';
import { useLocation } from 'react-router';

import Toast from '@components/Toast/Toast';
import { ToastItem } from '@customTypes/toast';

interface ToastContextProps {
  toasts: ToastItem[];
  addToast: (toast: Omit<ToastItem, 'id'>) => void;
  setToasts: React.Dispatch<React.SetStateAction<ToastItem[]>>;
}

interface Props {
  children: React.ReactNode;
}

const ToastContext = createContext<ToastContextProps>({} as ToastContextProps);

export const ToastProvider: React.FC<Props> = ({ children }) => {
  const { pathname } = useLocation();

  const [toasts, setToasts] = useState<ToastItem[]>([]);

  const addToast = (toast: Omit<ToastItem, 'id'>) => {
    setToasts(toasts => [...toasts, { ...toast, id: uuidv4() }]);
  };

  const memoedContextValue = useMemo(
    () => ({
      toasts,
      setToasts,
      addToast,
    }),
    [toasts],
  );

  const onToastClose = useCallback((toastId: string) => {
    setToasts(toasts => toasts.filter(toast => toast.id !== toastId));
  }, []);

  /**
   * Hide all the toasts on route change
   */
  useEffect(() => {
    setToasts([]);
  }, [pathname]);

  return (
    <ToastContext.Provider value={memoedContextValue}>
      {children}

      {toasts.length > 0 && (
        <ToastContainer aria-live='polite' aria-atomic='true' className='p-3 position-fixed cp-toast-container'>
          {toasts.map(toastItem => (
            <Toast key={toastItem.id} {...toastItem} onClose={() => onToastClose(toastItem.id)} />
          ))}
        </ToastContainer>
      )}
    </ToastContext.Provider>
  );
};

export default function useToast() {
  return useContext(ToastContext);
}

import { useCallback, useContext, useEffect, useState } from 'react';

import { Clinician, OrganizationRole } from '@customTypes/clinician';
import { fetchOrganizationProviders } from '@store/actions';
import { Context } from '@store/store';

interface IUseUsers {
  fetchUsers: () => Promise<void>;
  clinicians: Clinician[];
}

export default function useUsers(): IUseUsers {
  const [state, dispatch] = useContext(Context);

  const [clinicians, setClinicians] = useState<Clinician[]>([]);

  const fetchUsers = useCallback(async () => {
    if (state.clinicId) {
      fetchOrganizationProviders(state.clinicId).then(users => dispatch({ type: 'SET_USERS', payload: users }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.clinicId]);

  useEffect(() => {
    setClinicians(state.users.filter(user => (user.organizational_roles || []).includes(OrganizationRole.CLINICIAN)));
  }, [state.users]);

  return {
    fetchUsers,
    clinicians,
  };
}

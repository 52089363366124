import { useState, useRef, useEffect, ReactNode } from 'react';

import { Card, Button } from 'react-bootstrap';

import useViewportSizing from '@hooks/useViewportSizing';

export interface IOrderProcessStep {
  step: string;
  title: string;
  description: ReactNode;
}

export default function OrderStatusCard({ step, title, description }: IOrderProcessStep) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isTruncated, setIsTruncated] = useState(false);
  const descriptionRef = useRef<HTMLParagraphElement>(null);
  const { windowSize } = useViewportSizing();

  useEffect(() => {
    if (descriptionRef.current) {
      const lineHeight = parseFloat(getComputedStyle(descriptionRef.current).lineHeight || '0');
      const maxHeight = lineHeight * 3;

      setIsTruncated(descriptionRef.current.scrollHeight > maxHeight);
    }
  }, [description, windowSize.width]);

  return (
    <Card className='align-self-center align-self-lg-stretch order-status-card'>
      <Card.Body className='d-flex align-items-start flex-column'>
        <p className='mb-0 text-uppercase text-second fw-bold fs-body small'>{step}</p>
        <p className='mb-1 fw-semibold fs-6 lh-xs'>{title}</p>
        <p ref={descriptionRef} className={`text-left mb-0 fs-body ${isExpanded ? '' : 'text-truncate-3'}`}>
          {description}
        </p>
        {isTruncated && (
          <Button
            variant='link'
            size='sm'
            className='p-0 mt-1 text-decoration-none fs-body small fw-bold'
            onClick={() => setIsExpanded(!isExpanded)}
          >
            ({isExpanded ? 'less' : 'more'})
          </Button>
        )}
      </Card.Body>
    </Card>
  );
}

import { useContext, useMemo, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { orderBy } from 'lodash';
import { DatatableWrapper, TableBody, TableColumnType, TableHeader } from 'react-bs-datatable';
import { Button, Table } from 'react-bootstrap';

import ExpandableSection from '@components/ExpandableSection/ExpandableSection';
import { IExtendedClinician, OrganizationRole } from '@customTypes/clinician';
import { getClinicianNameWithLastNameFirst } from '@helpers/clinician';
import useToast from '@contexts/useToast';
import { Context } from '@store/store';
import { USER_ROLE_MAPPING } from '@constants/clinician';
import { ToastType } from '@customTypes/toast';
import { updateUserRoles } from '@store/actions';

import EditUserDialog from '../EditUserDialog/EditUserDialog';

export default function UserRoleManagement() {
  const [state, dispatch] = useContext(Context);
  const { addToast } = useToast();

  const [editUser, setEditUser] = useState<IExtendedClinician | null>(null);

  const headers = useMemo(
    () =>
      [
        { title: 'Name', prop: 'label', alignment: { horizontal: 'left' } },
        { title: 'Email', prop: 'email', alignment: { horizontal: 'left' } },
        { title: 'User Role(s)', prop: 'roles', alignment: { horizontal: 'left' } },
        {
          title: 'Edit',
          prop: 'id',
          alignment: { horizontal: 'left' },
          cell: (row: IExtendedClinician) => {
            return (
              <Button size='sm' variant='ghost' className='user-edit-btn' onClick={() => onRowClick(row)}>
                <FontAwesomeIcon icon={faPencil} className='success' fontSize={16} />
              </Button>
            );
          },
        },
      ] as TableColumnType<IExtendedClinician>[],
    [],
  );

  const onRowClick = (row: IExtendedClinician) => {
    setEditUser({ ...row });
  };

  const clinicians = useMemo(() => {
    const result = state.users.map(item => ({
      ...item,
      label: getClinicianNameWithLastNameFirst(item),
      email: item.contact_info?.[0]?.email,
      roles: (item.organizational_roles || [])
        .map(role => USER_ROLE_MAPPING[role])
        .sort((a, b) => a.localeCompare(b))
        .join(', '),
    })) as IExtendedClinician[];

    return orderBy(result, ['label'], ['asc']);
  }, [state.users]);

  const onUserChange = async (user: IExtendedClinician) => {
    try {
      // Do not send CLINICIAN role for now
      const rolesForUpdate: OrganizationRole[] = (user.organizational_roles || []).filter(
        role => role !== OrganizationRole.CLINICIAN,
      );
      await updateUserRoles(state.clinicId!, user.clinician_id!, rolesForUpdate);

      dispatch({ type: 'UPDATE_USER', payload: user });
      addToast({
        headerTitle: `${user.label} successfully updated`,
        type: ToastType.DEFAULT,
      });
    } catch (err) {
      console.error('Error on updating user roles: ', err);
    }
  };

  return (
    <>
      <ExpandableSection
        id='userRoleManage'
        expandableContentId='userRoleManageContent'
        headerTitle='Manage User Roles'
      >
        <DatatableWrapper body={clinicians} headers={headers}>
          <Table responsive>
            <TableHeader />
            <TableBody />
          </Table>
        </DatatableWrapper>
      </ExpandableSection>

      {!!editUser && <EditUserDialog user={editUser} onClose={() => setEditUser(null)} onConfirm={onUserChange} />}
    </>
  );
}

import { useRef } from 'react';

import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import FileSaver from 'file-saver';

import CommonDialog from '@components/Modals/CommonDialog';
import RequisitionReceiptContent from '@components/Requisition/RequisitionReceiptContent/RequisitionReceiptContent';
import { Requisition } from '@customTypes/requisition';
import { useRequisition } from '@hooks/useRequisition';

interface IProps {
  requisition: Requisition;
  onClose: () => void;
}

export default function OrderReceiptDialog({ requisition, onClose }: IProps) {
  const { requisitionId } = useRequisition(requisition);
  const contentRef = useRef<HTMLDivElement | null>(null);

  const handleDownload = async () => {
    const element = contentRef.current;
    if (!element) return;

    const canvas = await html2canvas(element);
    const imgData = canvas.toDataURL('image/png');
    // eslint-disable-next-line new-cap
    const pdf = new jsPDF('p', 'in', 'letter');
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

    pdf.addImage(imgData, 'PNG', 0, 0.5, pdfWidth, pdfHeight);

    // Create a Blob from the PDF
    const pdfBlob = pdf.output('blob');

    FileSaver.saveAs(pdfBlob, 'RequisitionReceipt.pdf');
    onClose();
  };

  return (
    <CommonDialog
      onClose={onClose}
      onConfirm={handleDownload}
      title='Order Receipt'
      modalProps={{
        'data-testid': 'orderReceiptDialog',
        'aria-labelledby': `order_receipt_${requisitionId}`,
        id: 'order-receipt-dialog',
        size: 'lg',
      }}
      cancelBtnProps={{
        'data-testid': 'closeOrderReceiptDialog',
        children: 'Close',
      }}
      confirmBtnProps={{ children: 'Download', className: 'd-none d-lg-block' }}
      titleProps={{
        id: `order_receipt_${requisitionId}`,
      }}
    >
      <RequisitionReceiptContent ref={contentRef} requisition={requisition} />
    </CommonDialog>
  );
}

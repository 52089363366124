import { useContext, useMemo, useState } from 'react';

import { orderBy } from 'lodash';
import { Form } from 'react-bootstrap';

import ExpandableSection from '@components/ExpandableSection/ExpandableSection';
import ProgressBar from '@components/ProgressBar/ProgressBar';
import UserTypeahead from '@components/Typeahead/UserTypeahead';
import UserManagementForm from '@components/UserManagementForm/UserManagementForm';
import { Clinician, IClinicianOption } from '@customTypes/clinician';
import { getClinicianNameWithLastNameFirst } from '@helpers/clinician';
import useEffectExceptMount from '@hooks/useEffectExceptMount';
import useUsers from '@hooks/useUsers';
import { fetchClinicalTeamByProvider } from '@store/actions';
import { Context } from '@store/store';

export default function DelegateManagement() {
  const { clinicians } = useUsers();
  const [state, dispatch] = useContext(Context);

  const [selectedClinician, setSelectedClinician] = useState<IClinicianOption[]>([]);
  const [clinicianMembers, setClinicianMembers] = useState<Clinician[]>([]);
  const [loading, setLoading] = useState(false);
  const options: IClinicianOption[] = useMemo(() => {
    const result = clinicians.map(delegate => ({
      ...delegate,
      label: getClinicianNameWithLastNameFirst(delegate),
      id: delegate.clinician_id ?? '',
    }));

    return orderBy(result, ['label'], ['asc']);
  }, [clinicians]);

  const onAddUser = (user: Clinician) => {
    setClinicianMembers(members => members.concat(user));
  };

  const onRemoveUser = (userId: string) => {
    setClinicianMembers(members => members.filter(user => user.clinician_id !== userId));
  };

  useEffectExceptMount(() => {
    if (selectedClinician.length && state.clinicId) {
      setLoading(true);
      fetchClinicalTeamByProvider(state.clinicId, selectedClinician[0].clinician_id!)
        .then(clinicalTeam => setClinicianMembers([...clinicalTeam]))
        .finally(() => {
          setLoading(false);
        });
    } else {
      // Clear clinician team members once a select clinician is cleared
      setClinicianMembers([]);
    }
  }, [selectedClinician, state.clinicId]);

  // Update clinicalTeam data in the Store
  useEffectExceptMount(() => {
    if (selectedClinician.length > 0 && state.currentClinician?.clinician_id === selectedClinician[0].clinician_id) {
      dispatch({ type: 'SET_CLINICAL_TEAM', payload: [...clinicianMembers] });
    }
  }, [state.currentClinician?.clinician_id, clinicianMembers, selectedClinician]);

  return (
    <ExpandableSection
      id='delegatesForOrderingProvider'
      expandableContentId='delegatesForOrderingProviderContent'
      headerTitle='Manage Delegates for an Ordering Provider'
    >
      <Form className='manage-delegate-form'>
        <Form.Group className='d-flex flex-column align-items-start'>
          <Form.Label>Select an ordering provider</Form.Label>
          <UserTypeahead
            clearButton
            id='clinicianSearchTypeahead'
            placeholder='Begin typing...'
            options={options}
            selected={selectedClinician}
            onChange={(value: unknown) => setSelectedClinician(value as IClinicianOption[])}
          />
        </Form.Group>
      </Form>

      {selectedClinician.length === 0 ? (
        <div className='bg-tertiary no-content'>
          <p>Select an ordering provider to display their delegates</p>
        </div>
      ) : loading ? (
        <div className='w-100' role='presentation'>
          <p className='fs-5 fw-medium lh-sm'>Loading...</p>
          <ProgressBar />
        </div>
      ) : (
        <UserManagementForm
          selectedUsers={clinicianMembers}
          selectedProvider={selectedClinician[0]}
          onAddUser={onAddUser}
          onRemoveUser={onRemoveUser}
          noContentChildren={
            <div className='d-flex flex-column gap-2 align-items-center'>
              <p>No delegates are currently assigned to the ordering provider&apos;s clinical team</p>
              <p className='second-line'>Use the search field to find and assign a delegate</p>
            </div>
          }
        />
      )}
    </ExpandableSection>
  );
}
